import React, { useEffect, useState } from "react";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card, Button, UncontrolledTooltip } from "reactstrap";
import PrimarySearch from "components/Common/PrimarySearch";
import PrimaryTable from "components/Common/PrimaryTable";
import { getEmailsList, emailAction } from "services/DMCAEmailsServices";
import { useNavigate } from "react-router-dom";
import ResetButton from "components/Common/ResetButton";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import tickIcon from "assets/images/tick-icon.svg";
import cancleIcon from "assets/images/cancle-icon.svg";
import counterIcon from "assets/images/counter-icon.svg";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { setPageTitle } from "utils/pageTitle";
import { dmcaDelete } from "services/DMCAEmailsServices";
import DeleteModal from "components/Common/Toast/DeleteModal";
import deleteIcon from "assets/images/delete-icon-white.svg";
import { useSelector } from "react-redux";
import DmcaActionPopup from "components/Common/Popups/DmcaActionPopup";
import moment from "moment";
import DmcaPopup from "components/Common/Popups/DmcaPopup";

const DMCAEmailsList = () => {
  const [emailsData, setEmailsData] = useState();
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchVal, setSearch] = useState();
  const [status, setStatus] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [showDmcaPopup, setShowDmcaPopup] = useState(false);

  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);
  const history = useNavigate();
  const queryParams = new URLSearchParams(window?.location?.search);

  useEffect(() => {
    setPageTitle("DMCA - Inspire Uplift");
  }, []);

  useEffect(() => {
    setLoading(true);
    const searchParam = queryParams?.get("search") || undefined;
    const pageParam = queryParams?.get("page") || undefined;
    const statusParam = queryParams?.get("status") || undefined;
    setSearch(searchParam || "");
    setPageNo(pageParam || 1);
    let statusValue;
    switch (statusParam) {
      case "Pending":
        statusValue = "0";
        break;
      case "Approved":
        statusValue = 2;
        break;
      case "Counter":
        statusValue = 1;
        break;
      case "Rejected":
        statusValue = 3;
        break;
      default:
        statusValue = "";
    }
    setStatus(statusValue);
    getEmails(pageParam, searchParam, statusValue);
  }, [window?.location?.search]);

  const getEmails = (page, search, status) => {
    getEmailsList(page, search, status)
      .then(res => {
        if (res?.data?.data?.length == 0 && page > 1) {
          queryParams?.set("page", page - 1);
          const newUrl = `${
            window.location.pathname
          }?${queryParams?.toString()}`;
          history(newUrl, { replace: true });
        } else {
          setEmailsData(res?.data?.data);
          setPageCount(res?.data.last_page);
        }
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const columns = [
    {
      dataField: "id",
      text: "#",
      formatter: (cell, row) => {
        return (
          <div className="font-semi-bold">{(pageNo - 1) * 15 + row.index}</div>
        );
      },
    },
    {
      dataField: "name",
      text: "Name",
      formatter: (cell, row) => (
        <div>
          {row.first_name} {row?.last_name}
        </div>
      ),
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <div
          className={`${
            row.status === "pending"
              ? "warning-status"
              : row.status === "accept"
              ? "success-status"
              : row.status === "reject"
              ? "danger-status"
              : row.status === "counter"
              ? "info-status"
              : ""
          } status w-max-content text-capitalize`}
        >
          {row?.status}
        </div>
      ),
    },
    {
      dataField: "created_at",
      text: "Created At",
      formatter: (cell, row) => {
        return (
          <div>{moment(row.created_at).format("DD-MM-YYYY hh:mm:ss a")}</div>
        );
      },
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="d-flex items-center">
            {" "}
            {row?.status == "pending" ? (
              <div className="d-flex align-items-center">
                {permissions?.includes("dmca-reject") ? (
                  <div
                    className="action-btn delete-btn-bg me-1 cursor-pointer"
                    onClick={e => handleAction(e, 3, row?.id)}
                  >
                    <img src={cancleIcon} className="" alt="delete icon" />
                  </div>
                ) : null}

                {permissions?.includes("dmca-approve") ? (
                  <div
                    className="action-btn edit-icon-bg me-1 cursor-pointer"
                    onClick={e => {
                      e.stopPropagation();
                      handleAction(e, 2, row?.id);
                    }}
                  >
                    <img src={tickIcon} className="" alt="edit icon" />
                  </div>
                ) : null}
                {permissions?.includes("dmca-counter") ? (
                  <div>
                    <div
                      className="action-btn edit-icon-bg me-1 cursor-pointer"
                      onClick={e => handleAction(e, 1, row?.id)}
                      id={`tooltip-${row.id}`}
                    >
                      <img src={counterIcon} className="" alt="edit icon" />
                    </div>
                    <UncontrolledTooltip
                      target={`tooltip-${row.id}`}
                      placement="bottom"
                    >
                      Counter
                    </UncontrolledTooltip>
                  </div>
                ) : null}
              </div>
            ) : null}
            {permissions?.includes("dmca-delete") ? (
              <div
                className="action-btn delete-btn-bg me-1"
                onClick={e => {
                  e.stopPropagation();
                  setSelectedId(row?.id);
                  setShowDeleteModal(true);
                }}
              >
                <img src={deleteIcon} className="" alt="delete icon" />
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  const data = emailsData?.map((item, index) => {
    return {
      ...item,
      index: index + 1,
    };
  });

  const handleKeyPress = e => {
    if (e.key == "Enter") {
      if (searchVal) {
        queryParams.set("search", searchVal);
      } else {
        queryParams.delete("search");
      }
      queryParams.delete("page");
      const newURL = `${window?.location?.pathname}?${queryParams?.toString()}`;
      history(newURL);
    }
  };

  const handleAction = (e, actionType, id) => {
    e.stopPropagation();
    let payload = {
      status: actionType,
      id,
    };
    setLoading(true);
    emailAction(payload)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: res?.data?.message,
        });
        getEmails(pageNo, searchVal, status);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        PrimaryToast({
          type: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  const handleFilter = () => {
    let statusVal;
    if (status == 0) {
      statusVal = "Pending";
    } else if (status == 1) {
      statusVal = "Counter";
    } else if (status == 2) {
      statusVal = "Approved";
    } else if (status == 3) {
      statusVal = "Rejected";
    }
    if (status) {
      queryParams?.set("status", statusVal);
    } else {
      queryParams?.delete("status");
    }
    if (searchVal) {
      queryParams?.set("search", searchVal);
    } else {
      queryParams?.delete("search");
    }

    if (queryParams.get("page")) {
      queryParams.delete("page");
    }
    history(`${location?.pathname}?${queryParams?.toString()}`);
  };

  const handleModal = () => {
    setShowDeleteModal(!showDeleteModal);
    setSelectedId("");
  };

  const handleDelete = () => {
    setLoading(true);
    dmcaDelete([selectedId])
      .then(res => {
        PrimaryToast({
          type: "success",
          message: res?.data?.message,
        });
        getEmails(pageNo, searchVal, status);
        handleModal();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        PrimaryToast({
          type: "error",
          message: Array.isArray(err?.response?.data?.message)
            ? err?.response?.data?.message[0]
            : err?.response?.data?.message,
        });
      });
  };

  return (
    <div className="page-content">
      {showDeleteModal && (
        <DeleteModal
          title={"Are you sure you want to delete this DMCA"}
          onCancel={handleModal}
          onConfirm={handleDelete}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="d-flex justify-content-between align-items-center">
        <TopHeading heading={"DMCA"} />
        <Button
          color="primary"
          onClick={() => setShowDmcaPopup(!showDmcaPopup)}
        >
          Create DMCA
        </Button>
      </div>
      <Card className="mt-4">
        <CardBody>
          <div className="d-flex align-items-center mb-4">
            <PrimarySearch
              placeholder={"Search by emails..."}
              handleChange={e => setSearch(e.target.value)}
              handleKeyPress={handleKeyPress}
              value={searchVal}
            />
            <select
              name="status"
              onChange={e => setStatus(e.target.value)}
              value={status}
              className="ms-4 primary-select-option"
            >
              <option value={""}>Search By Status</option>
              <option value={"0"}>Pending</option>
              <option value={2}>Accept</option>
              <option value={3}>Rejected</option>
              <option value={1}>Counter</option>
            </select>
            <Button
              color="primary"
              className={`btn-rounded ms-2`}
              onClick={handleFilter}
              disabled={
                searchVal?.length == 0 &&
                status?.length == 0 &&
                queryParams?.toString()?.length == 0
              }
            >
              Apply Filter
            </Button>
            {queryParams?.toString()?.length > 0 && <ResetButton />}
          </div>
          <div>
            <PrimaryTable
              data={data}
              columns={columns}
              keyField={"id"}
              bordered={false}
              isPermission
              rowUrl={"/others/dmca-emails"}
            />
          </div>
          {emailsData?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          )}
          {showDmcaPopup && (
            <DmcaPopup
              isOpen={showDmcaPopup}
              handleClick={() => setShowDmcaPopup(!showDmcaPopup)}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default DMCAEmailsList;
