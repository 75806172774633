import React from "react";
import { Card, CardBody } from "reactstrap";

function ClientDetailsComponent({ clientDetail }) {
    return (
        <div>
            <Card>
                <CardBody>
                    
                    <div className="font-semi-bold h5 mb-0">Client Details</div>
                    <div className="mt-2">{clientDetail?.country_code}</div>
                    <p className="mt-2 mb-0">{clientDetail?.ip}</p>
                    <p className="mt-2 mb-0">{clientDetail?.user_agent}</p>
                    {
                        clientDetail?.referrer ? (
                            <p className="mt-2 mb-0">{clientDetail?.referrer}</p>
                        ) :null
                    }
                </CardBody>
            </Card>
        </div>
    )
}

export default ClientDetailsComponent;