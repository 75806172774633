import React, { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import { Button } from "reactstrap";

import QuantityInput from "../QuantityInput";
import { getTrackProductDetail } from "services/OrderManagementService";
import { substractVariantTitle } from "utils/string";
import AliExpressProductSearchPopup from "./AliExpressProductSearchPopup";
import {
  aePlaceOrder,
  getAEProductsByIds,
  getAeUserInfo,
} from "services/AliExpressServices";
import PhoneDetailsFormPopup from "./PhoneDetailsFormPopup";
import PrimaryToast from "../Toast/ToastMessage";

export default function AddTrackingPopup({
  handleClick,
  id,
  handleCreateTracking,
  loading,
}) {
  const [trackingData, setTrackingData] = useState();
  const [quantityData, setQuantityData] = useState({
    variant_id: [],
    product_id: [],
    lineItemQty: [],
  });
  const [trackingNumber, setTrackingNumber] = useState();
  const [aliExpressNumbers, setAliExpressNumber] = useState();
  const [searchProductId, setSearchProductId] = useState("");
  const [isSearchProduct, setIsSearchProduct] = useState(false);
  const [variantsData, setVariantsData] = useState([]);
  const [selectedVariant, setSelectedVariant] = useState([]);
  const [searchingRowId, setSearchingRowId] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState({});
  const [isAddPhoneDetail, setIsAddPhoneDetail] = useState(false);
  const [phoneFields, setPhoneFields] = useState({});
  const [aeUserInfo, setAeUserInfo] = useState({});
  const [btnLoading, setLoading] = useState(false);

  const customStyles = {
    control: provided => ({
      ...provided,
      borderRadius: "15px",
    }),
  };

  const handleSelectedVariant = (selected, rowId) => {
    // Update the selectedVariant state to store data for this row
    setSelectedVariant(prevState => ({
      ...prevState,
      [rowId]: {
        ...selected,
        id: rowId, // Store the rowId with the selected variant
      },
    }));
  };

  useEffect(() => {
    getTrackProductDetail(id)
      .then(res => {
        getUserData();
        const data = res?.data;
        setTrackingData(data);

        if (data && Object.keys(data.line_items).length > 0) {
          const { line_items } = data;
          const variant_ids = [];
          const product_ids = [];
          const lineItemQty = [];

          Object.keys(line_items).forEach(key => {
            line_items[key].forEach(item => {
              variant_ids.push(item.variant_id);
              product_ids.push(item.product_id);
              lineItemQty.push(item.quantity);
            });
          });

          setQuantityData({
            variant_id: variant_ids,
            product_id: product_ids,
            lineItemQty: lineItemQty,
          });
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const getUserData = async () => {
    try {
      const res = await getAeUserInfo();
      if (res.data?.length > 0) {
        setAeUserInfo(res.data[0]);
      }
    } catch (error) {
      console.log(error, "err");
    }
  };

  const handleChange = (item, quantity) => {
    const { variant_id, product_id, lineItemQty } = quantityData;
    const index = variant_id.indexOf(item?.variant_id);

    if (quantity === 0 && index > -1) {
      // If the quantity becomes zero, remove the corresponding data from the arrays
      variant_id.splice(index, 1);
      product_id.splice(index, 1);
      lineItemQty.splice(index, 1);
    } else if (index > -1) {
      // If the item already exists, update the quantity
      lineItemQty[index] = quantity;
    } else {
      // If the item doesn't exist, add a new entry
      variant_id.push(item.variant_id);
      product_id.push(item?.product_id);
      lineItemQty.push(quantity);
    }

    setQuantityData({
      variant_id,
      product_id,
      lineItemQty,
    });
  };

  const handleProductSearchById = useCallback(e => {
    setSearchProductId(e.target.value);
  }, []);

  const searchProductById = useCallback(async rowId => {
    setLoading(true);
    try {
      const res = await getAEProductsByIds(searchProductId, aeUserInfo?.email);
      PrimaryToast({
        type:"success",
        message:"Product found successfully!"
      })
      const customTypes = res?.data
        .filter(item => item.properties && item.sku_attr) // Filter out items where properties or sku_attr is empty
        .map(item => ({
          label: item.properties,
          value: item.properties,
          sku_attr: item.sku_attr,
          ae_product_id: searchProductId,
        }));
      if (customTypes?.length === 0) {
        setSelectedVariant(prevState => ({
          ...prevState,
          [rowId]: {
            sku_attr: null,
            ae_product_id: searchProductId,
            id: rowId, // Store the rowId with the selected variant
          },
        }));
      }
      setVariantsData(prev => ({
        ...prev,
        [rowId]: customTypes, // Store variant data for this row
      }));
      setSearchingRowId(null);
      handleSearchProductPopup();
    } catch (error) {
      console.log(error, "check error");
      PrimaryToast({
        type: "error",
        message: Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message[0]
          : error?.response?.data?.message,
      });
    } finally {
      setLoading(false);
    }
  }, [searchProductId]);

  const handleSearchProductPopup = () => {
    setIsSearchProduct(pre => !pre);
    if (isSearchProduct) {
      setSearchProductId("");
    }
  };

  const handleSearchProductIdClick = rowId => {
    setSearchingRowId(rowId); // Set the current row as being searched
    setSearchProductId(""); // Reset the search field when starting a new search
    setIsSearchProduct(pre => !pre);
  };

  const handleCheckboxChange = (variantId, isChecked) => {
    setSelectedProducts(prevState => ({
      ...prevState,
      [variantId]: isChecked, // Update the state based on checkbox status
    }));
  };

  const getSelectedQuantities = () => {
    // Create an array of selected products with their quantities
    const selectedQuantities = Object.keys(selectedProducts)
      .filter(variantId => selectedProducts[variantId])
      .map(variantId => {
        // Find the index of the variant_id in quantityData.variant_id
        const index = quantityData.variant_id.indexOf(parseInt(variantId)); // Ensure the variantId is an integer

        if (index !== -1) {
          // Return the variantId, quantity, and productId
          return {
            variant_id: variantId,
            quantity: quantityData.lineItemQty[index], // Get the corresponding quantity
            product_id: quantityData.product_id[index], // Get the product_id
          };
        }

        return null; // In case the variantId is not found in quantityData
      })
      .filter(item => item !== null); // Remove any null values

    return selectedQuantities;
  };

  const handleAETracking = async e => {
    try {
      const productsWithQuantities = getSelectedQuantities();
      const productsData = productsWithQuantities?.map(item => {
        return {
          ae_product_id: selectedVariant[item?.variant_id]?.ae_product_id,
          sku_attr: selectedVariant[item.variant_id]?.sku_attr,
          variant_id: item.variant_id,
          quantity: item.quantity,
        };
      });
      const payload = {
        order_id: id,
        account_email: aeUserInfo?.email,
        phone: phoneFields?.phone,
        phone_country: phoneFields?.phone_country,
        products: productsData,
      };
      setLoading(true);
      const res = await aePlaceOrder(payload);
      const aeOrderNumber =
        res.data.data.aliexpress_ds_order_create_response?.result?.order_list
          ?.number?.[0];
      handleCreateTracking(quantityData, trackingNumber, aeOrderNumber);
    } catch (error) {
      console.log(error, "error");
      PrimaryToast({
        type: "error",
        message: Array.isArray(error?.response?.data?.message)
          ? error?.response?.data?.message[0]
          : error?.response?.data?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handlePhoneFieldsChange = useCallback(e => {
    const { name, value } = e.target;
    setPhoneFields(pre => ({
      ...pre,
      [name]: value,
    }));
  }, []);

  const handlePhoneFieldsShowPopup = () => {
    setIsAddPhoneDetail(pre => !pre);
    if (isAddPhoneDetail) {
      setPhoneFields({});
    }
  };
  const handlePhoneValidation = () => {
    if (Object.values(phoneFields).length == 0) {
      PrimaryToast({
        type: "error",
        message: "Enter phone number",
      });
      return;
    }
    if (!phoneFields?.phone) {
      PrimaryToast({
        type: "error",
        message: "Enter phone number",
      });
      return;
    } else if (!phoneFields?.phone_country) {
      PrimaryToast({
        type: "error",
        message: "enter phone country",
      });
      return;
    }
    return true;
  };

  const handlePlaceAEOrder = () => {
    if (handlePhoneValidation()) {
      handleAETracking();
    }
  };

  const hasSelectedProducts = Object.values(selectedProducts).some(
    value => value === true
  );

  return (
    <>
      <div>
        <div className="order-refund-popup-table-header d-flex  align-items-center mb-4">
          <div className="me-4">#</div>
          <div className="d-flex justify-content-between align-items-center w-100">
            <div style={{ width: "300px" }}>Product</div>
            <div className="product-title-container">Variant</div>
            <div>Quantity</div>
          </div>
        </div>
        {trackingData && Object.keys(trackingData).length > 0
          ? Object.keys(trackingData.line_items).map((item, index) => {
              const lineItem = trackingData.line_items[item];
              return (
                <div className="order-refund-popup-table-body mb-4" key={index}>
                  <div className="font-semi-bold h5 mb-0 order-refund-seller-name">
                    {item}
                  </div>
                  {lineItem?.map((item1, index1) => (
                    <div
                      className="d-flex align-items-center  py-3 border-bottom"
                      key={index1}
                    >
                      <div className="me-4 fw-bold d-flex">
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          checked={selectedProducts[item1.variant_id] || false}
                          onChange={e =>
                            handleCheckboxChange(
                              item1.variant_id,
                              e.target.checked
                            )
                          }
                        />
                        <span>{index1 + 1}</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between w-100">
                        <div
                          className="product-title-container"
                          style={{ width: "350px" }}
                        >
                          <p>{item1?.title}</p>
                          <div
                            className="add-product-id-btn"
                            onClick={() =>
                              handleSearchProductIdClick(item1.variant_id)
                            }
                          >
                            <svg
                              width="13"
                              height="14"
                              viewBox="0 0 13 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              className="me-2"
                            >
                              <path
                                d="M13 6.10714H7.64286V0.75H5.85714V6.10714H0.5V7.89286H5.85714V13.25H7.64286V7.89286H13V6.10714Z"
                                fill="#556EE6"
                              />
                            </svg>
                            Add Product ID
                          </div>
                        </div>
                        {
                          <div className="product-title-container">
                            {item1?.type == "configurable"
                              ? substractVariantTitle(
                                  item1?.title,
                                  item1?.variant_title
                                )
                              : null}
                            {Object.keys(variantsData).map(item => {
                              if (item == item1.variant_id) {
                              }
                            })}
                            {variantsData[item1.variant_id] &&
                              variantsData[item1.variant_id].length > 0 && (
                                <div className="product-title-container mt-2">
                                  <Select
                                    options={variantsData[item1.variant_id]} // Use the variant data for this row
                                    onChange={e =>
                                      handleSelectedVariant(e, item1.variant_id)
                                    }
                                    value={selectedVariant[item1.variant_id]}
                                    name="seller_groups_ids"
                                    placeholder="Select Variant"
                                    styles={customStyles}
                                  />
                                </div>
                              )}
                          </div>
                        }
                        <QuantityInput
                          totalQuantity={item1?.quantity}
                          handleChange={handleChange}
                          item={item1}
                          path="tracking"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              );
            })
          : null}
        <div className="refund-order-summary-section-container my-4 d-flex justify-content-end border-bottom">
          <div className="refund-order-summary-section-inner-wrapper">
            <div className="font-semi-bold h5 mb-0 py-3 border-bottom">
              Add Tracking
            </div>
            <div className=" mb-3">
              <label htmlFor="inputEmail4" className="form-label">
                Tracking Number
              </label>
              <div className="position-relative">
                <input
                  type="text"
                  name="tracking"
                  value={trackingNumber}
                  onChange={e => setTrackingNumber(e.target.value)}
                  className={`form-control w-100`}
                  id="inputEmail4"
                  disabled={hasSelectedProducts > 0}
                  placeholder="e.g: RB029673326SG"
                />
                <div className="tracking-copy-btn position-absolute"></div>
              </div>
            </div>
            <div className=" mb-3">
              <label htmlFor="inputEmail4" className="form-label">
                Ali Express Number
              </label>
              <div className="position-relative">
                <input
                  disabled={hasSelectedProducts > 0}
                  type="text"
                  name="aliExpressNumbers"
                  value={aliExpressNumbers}
                  onChange={e => setAliExpressNumber(e.target?.value)}
                  className={`form-control w-100`}
                  id="inputEmail4"
                  placeholder="e.g: RB029673326SG"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="order-summary-action-section d-flex justify-content-end">
          <div className="d-flex align-items-center">
            <Button color="secondary" className="me-4" onClick={handleClick}>
              Cancel
            </Button>

            <Button
              disabled={loading}
              color="primary"
              onClick={() => {
                if (hasSelectedProducts) {
                  handlePhoneFieldsShowPopup();
                } else {
                  handleCreateTracking(
                    quantityData,
                    trackingNumber,
                    aliExpressNumbers
                  );
                }
              }}
            >
              Add Tracking {hasSelectedProducts > 0 && "With AE"}
            </Button>
          </div>
        </div>
      </div>
      {isSearchProduct && (
        <AliExpressProductSearchPopup
          handleProductSearchById={handleProductSearchById}
          searchProductId={searchProductId}
          isOpen={isSearchProduct}
          searchProductById={() => searchProductById(searchingRowId)}
          handlePopup={handleSearchProductPopup}
          loading={btnLoading}
        />
      )}
      {isAddPhoneDetail && (
        <PhoneDetailsFormPopup
          isOpen={isAddPhoneDetail}
          phoneFields={phoneFields}
          handlePhoneFieldsChange={handlePhoneFieldsChange}
          handlePopup={handlePhoneFieldsShowPopup}
          handlePlaceAEOrder={handlePlaceAEOrder}
          loading={btnLoading}
        />
      )}
    </>
  );
}
