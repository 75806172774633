import React, { useState } from "react";
import PrimaryPopup from "./PrimaryPopup";
import { Button } from "reactstrap";
import ProgressPopup from "./ProgressPopup";
import PrimaryToast from "../Toast/ToastMessage";
import { dmcaForm } from "services/DMCAEmailsServices";

function DmcaPopup({ isOpen, handleClick }) {
  const [loading, setLoading] = useState(false);
  const [productIds, setProductIds] = useState("");

  const handleChange = e => {
    setProductIds(e.target.value);
  };

  const handleAction = () => {
    setLoading(true);
    dmcaForm(productIds.split(","))
      .then(res => {
        PrimaryToast({
          type: "success",
          message: res?.data?.message,
        });
        window.location.reload();
      })
      .catch(err => {
        console.log(err, "err");
        PrimaryToast({
          type: "error",
          message: err?.response?.data?.message,
        });
      })
      .finally(res => setLoading(false));
  };
  return (
    <PrimaryPopup
      isOpen={isOpen}
      handleClick={handleClick}
      title={`Create DMCA`}
      isScrollable={false}
      className={`d-flex justify-content-center align-items-center h-100 w-100`}
    >
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="mb-3">
        <textarea
          rows="5"
          placeholder="Enter product ids separated by comma( , )"
          className="form-control mt-2"
          value={productIds}
          onChange={handleChange}
        />

        <div className="d-flex justify-content-end mt-4">
          <Button
            disabled={productIds?.length == 0 || !productIds}
            color="primary"
            onClick={handleAction}
          >
            Submit
          </Button>
        </div>
      </div>
    </PrimaryPopup>
  );
}

export default DmcaPopup;
