import React, { useEffect, useState } from "react";
import TopHeading from "components/Common/TopHeading";
import { CardBody, Card, Button } from "reactstrap";
import PrimarySearch from "components/Common/PrimarySearch";
import PrimaryTable from "components/Common/PrimaryTable";
import {
  getDmcaStrikedSellers,
  strikedSellersAction,
} from "services/DMCAEmailsServices";
import { useNavigate } from "react-router-dom";
import ResetButton from "components/Common/ResetButton";
import PrimaryPagination from "components/Common/Pagination/PrimaryPagination";
import ProgressPopup from "components/Common/Popups/ProgressPopup";
import tickIcon from "assets/images/tick-icon.svg";
import cancleIcon from "assets/images/cancle-icon.svg";
import PrimaryToast from "components/Common/Toast/ToastMessage";
import { setPageTitle } from "utils/pageTitle";
import DeleteModal from "components/Common/Toast/DeleteModal";
import { useSelector } from "react-redux";
import moment from "moment";
import DateRangePicker from "components/Common/DateRangePicker";

const DMCASellerApproval = () => {
  const [sellerApprovalData, setSellerApprovalData] = useState();
  const [loading, setLoading] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchVal, setSearch] = useState();
  const [status, setStatus] = useState();
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [showCalendar, setShowCalendar] = useState(false);

  const user = useSelector(state => state.auth.user);
  const permissions = user?.permissions?.map(item => item.name);
  const history = useNavigate();
  const queryParams = new URLSearchParams(window?.location?.search);

  useEffect(() => {
    setPageTitle("DMCA - Inspire Uplift");
  }, []);

  const defaultDates = [
    {
      startDate: queryParams.get("start")
        ? new Date(queryParams.get("start"))
        : new Date(),
      endDate: queryParams.get("end")
        ? new Date(queryParams.get("end"))
        : new Date(),
      key: "selection",
    },
  ];
  const [dates, setDates] = useState(defaultDates);
  const [isDateFilterApplied, setIsDateFilterApplied] = useState(false);

  useEffect(() => {
    setLoading(true);
    const searchParam = queryParams?.get("search") || undefined;
    const pageParam = queryParams?.get("page") || undefined;
    const statusParam = queryParams?.get("status") || undefined;
    const start = queryParams?.get("start") || undefined;
    const end = queryParams?.get("end") || undefined;
    setSearch(searchParam || "");
    setPageNo(pageParam || 1);
    setStatus(statusParam || "");
    setDates(defaultDates);
    if (queryParams?.get("start")) {
      setIsDateFilterApplied(true);
    } else {
      setIsDateFilterApplied(false);
    }
    getStrikedSellers(pageParam, searchParam, statusParam, start, end);
  }, [window?.location?.search]);

  const handleDatePicker = item => {
    setDates([
      {
        startDate: moment(item.selection.startDate).startOf("day").toDate(),
        endDate: moment(item.selection.endDate).endOf("day").toDate(),
        key: "selection",
      },
    ]);
  };
  const handleSubmit = () => {
    setIsDateFilterApplied(true);
    setShowCalendar(false);
  };

  const getStrikedSellers = (page, search, statusVal, start, end) => {
    if (queryParams?.get("start") && queryParams?.get("end")) {
      start = moment(dates[0]?.startDate)
        .startOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
      end = moment(dates[0]?.endDate)
        .endOf("day")
        .format("YYYY-MM-DD HH:mm:ss");
    }

    getDmcaStrikedSellers(page, search, statusVal, start, end)
      .then(res => {
        setSellerApprovalData(res?.data?.data);
        setPageCount(res?.data.last_page);
      })
      .catch(err => {
        console.log(err);
      })
      .finally(res => {
        setLoading(false);
      });
  };

  const columns = [
    {
      dataField: "seller_id",
      text: "Seller Id",
    },
    {
      dataField: "seller_name",
      text: "Seller Name",
      formatter: (cell, row) => <div>{row?.seller_name}</div>,
    },
    {
      dataField: "created_at",
      text: "Created At",
      formatter: (cell, row) => {
        return (
          <div>{moment(row.created_at).format("DD-MM-YYYY hh:mm:ss a")}</div>
        );
      },
    },
    {
      dataField: "updated_at",
      text: "Updated At",
      formatter: (cell, row) => {
        return row?.updated_at ? (
          <div>{moment(row.updated_at).format("DD-MM-YYYY hh:mm:ss a")}</div>
        ) : (
          ""
        );
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row) => (
        <div
          className={`${
            row.status === 0
              ? "warning-status"
              : row.status === 1
              ? "success-status"
              : row.status === 2
              ? "danger-status"
              : ""
          } status w-max-content text-capitalize`}
        >
          {row.status === 0
            ? "Pending"
            : row.status === 1
            ? "Accept"
            : row.status === 2
            ? "Reject"
            : ""}
        </div>
      ),
    },

    {
      dataField: "action_by",
      text: "Action By",
    },
    {
      dataField: "action",
      text: "Action",
      formatter: (cell, row) => {
        return (
          <div className="d-flex items-center">
            {row?.status === 0 ? (
              <div className="d-flex align-items-center">
                {permissions?.includes("dmca-seller-approval") ? (
                  <>
                    {/* Reject Button */}
                    <div
                      className="action-btn delete-btn-bg me-1 cursor-pointer"
                      onClick={e => {
                        e.stopPropagation();
                        openRejectModal(row?.id);
                      }}
                    >
                      <img src={cancleIcon} className="" alt="delete icon" />
                    </div>

                    {/* Approve Button */}
                    <div
                      className="action-btn edit-icon-bg me-1 cursor-pointer"
                      onClick={e => {
                        e.stopPropagation();
                        openApproveModal(row?.id);
                      }}
                    >
                      <img src={tickIcon} className="" alt="edit icon" />
                    </div>
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
        );
      },
    },
  ];

  const data = sellerApprovalData?.map((item, index) => {
    return {
      ...item,
      index: (pageNo - 1) * 15 + index + 1,
    };
  });

  const handleFilter = () => {
    if (status) {
      queryParams?.set("status", status);
    } else {
      queryParams?.delete("status");
    }
    if (searchVal) {
      queryParams?.set("search", searchVal);
    } else {
      queryParams?.delete("search");
    }
    if (isDateFilterApplied) {
      queryParams.set(
        "start",
        moment(dates[0]?.startDate).format("YYYY-MM-DD")
      );
      queryParams.set("end", moment(dates[0]?.endDate).format("YYYY-MM-DD"));
    } else {
      setDates(defaultDates); // Reset the dates state// Reset the applied date state
      setIsDateFilterApplied(false);
    }

    if (queryParams.get("page")) {
      queryParams.delete("page");
    }
    history(`${location?.pathname}?${queryParams?.toString()}`);
  };

  const handleKeyPress = e => {
    if (e.key == "Enter") {
      if (searchVal) {
        queryParams.set("search", searchVal);
      } else {
        queryParams.delete("search");
      }
      queryParams.delete("page");
      const newURL = `${window?.location?.pathname}?${queryParams?.toString()}`;
      history(newURL);
    }
  };

  const handleAction = (e, actionType, ids) => {
    e.stopPropagation();
    setLoading(true);
    strikedSellersAction(actionType, ids)
      .then(res => {
        PrimaryToast({
          type: "success",
          message: res?.data?.message,
        });
        getStrikedSellers();
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
        PrimaryToast({
          type: "error",
          message: err?.response?.data?.message,
        });
      });
  };

  const openRejectModal = id => {
    setSelectedId(id);
    setShowRejectModal(true);
  };

  const openApproveModal = id => {
    setSelectedId(id);
    setShowApproveModal(true);
  };

  const handleModalClose = () => {
    setShowRejectModal(false);
    setShowApproveModal(false);
    setSelectedId(""); // Clear selected ID
  };

  return (
    <div className="page-content">
      {showRejectModal && (
        <DeleteModal
          title={"Are you sure you want to Reject DMCA Strikes?"}
          onCancel={handleModalClose}
          onConfirm={e => {
            handleAction(e, 2, [selectedId]);
            setShowRejectModal(false);
          }}
        />
      )}

      {/* Approve Modal */}
      {showApproveModal && (
        <DeleteModal
          title={"Are you sure you want to Approve DMCA Strikes?"}
          onCancel={handleModalClose}
          onConfirm={e => {
            handleAction(e, 1, [selectedId]);
            setShowApproveModal(false);
          }}
        />
      )}
      {loading && <ProgressPopup label={"Loading..."} />}
      <div className="d-flex justify-content-between align-items-center">
        <TopHeading heading={"DMCA Seller Approval"} />
      </div>
      <Card className="mt-4">
        <CardBody>
          <div className="d-flex align-items-center mb-4">
            <PrimarySearch
              placeholder={"Search by Seller Name..."}
              handleChange={e => setSearch(e.target.value)}
              handleKeyPress={handleKeyPress}
              value={searchVal}
            />
            <div className="d-flex">
              <div className="position-relative">
                <div
                  className="form-control cursor-pointer rounded-full"
                  style={{ color: "#74788D", width: "max-content" }}
                  onClick={() => setShowCalendar(true)}
                >
                  <span>
                    {moment(dates[0].startDate).format("DD MMM YYYY")}
                  </span>{" "}
                  -{" "}
                  <span>{moment(dates[0].endDate).format("DD MMM YYYY")}</span>
                </div>

                {showCalendar && (
                  <>
                    <div
                      className="position-fixed top-0 start-0 w-100 h-100"
                      style={{ zIndex: "10" }}
                      onClick={() => setShowCalendar(false)}
                    ></div>
                    <DateRangePicker
                      dates={dates}
                      handleDatePicker={handleDatePicker}
                      handleSubmit={handleSubmit}
                      maxDate={new Date()}
                    />
                  </>
                )}
              </div>
            </div>
            <select
              name="status"
              onChange={e => setStatus(e.target.value)}
              value={status}
              className="ms-4 primary-select-option"
            >
              <option value={""}>Search By Status</option>
              <option value={"pending"}>Pending</option>
              <option value={"accept"}>Accept</option>
              <option value={"reject"}>Rejected</option>
            </select>
            <Button
              color="primary"
              className={`btn-rounded ms-2`}
              onClick={handleFilter}
              disabled={
                !dates[0]?.startDate ||
                (!dates[0]?.endDate &&
                  searchVal?.length == 0 &&
                  status?.length == 0 &&
                  queryParams?.toString()?.length == 0)
              }
            >
              Apply Filter
            </Button>
            {queryParams?.toString()?.length > 0 && <ResetButton />}
          </div>
          <div>
            <PrimaryTable
              data={data}
              columns={columns}
              keyField={"id"}
              bordered={false}
            />
          </div>
          {sellerApprovalData?.length > 0 && pageCount > 1 && (
            <PrimaryPagination
              pageCount={pageCount}
              pageNo={pageNo}
              setPageNo={setPageNo}
            />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default DMCASellerApproval;
