import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAdminAECredentials  = (code) => {
    return getApi({
        url:`admin/ae/credentials`,
        data:{
            is_admin:true,
            code:code || undefined
        },

    }).then(res => (res.data))
};

export const getAEProductsByIds  = (productId, email) => {
    return getApi({
        url:`admin/ae/product`,
        data:{
            is_admin:true,
            ae_product_id:productId || undefined,
            account_email:email || undefined,
        },

    }).then(res => (res.data))
};


export const aePlaceOrder = (payload) => {
    return axiosInstance({
        method: "POST",
        data: {
            is_admin:true,
            ...payload
        },
		withCredentials:true,
        url: `${siteApiUrl}admin/ae/order-place`,
        headers: getHttpHeaders(),
    });
};

export const getAeUserInfo  = () => {
    return getApi({
        url:`admin/ae/users`,
        data:{
            is_admin:true,
        },

    }).then(res => (res.data))
};