import React from "react";
import { Button } from "reactstrap";

const LoadingButton = ({ text, color = "primary", isLoading, handleClick }) => {
  return (
    <Button
      color={color}
      style={{
        padding: "10px",
        border: "none",
        borderRadius: "5px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: isLoading ? "not-allowed" : "pointer",
        opacity: isLoading ? 0.7 : 1,
      }}
      disabled={isLoading}
      onClick={handleClick}
    >

      {isLoading ? (
        <span>
          <svg
            className="animate-spin me-2"
            style={{ color: "#2A3042", height: "1rem", width: "1rem" }}
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            ></path>
          </svg>
          Loading...
        </span>
      ) : (
        text
      )}
    </Button>
  );
};

export default LoadingButton;
