import React from "react";
import PrimaryPopup from "./PrimaryPopup";
import PrimaryInput from "../PrimaryInput";
import { Button } from "reactstrap";
import LoadingButton from "../buttons/LoadingButton";

const AliExpressProductSearchPopup = ({ isOpen, handlePopup, searchProductId, handleProductSearchById, searchProductById, loading }) => {
  console.log(loading,"check loading state");
  return (
    <PrimaryPopup
      isOpen={isOpen}
      handleClick={handlePopup}
      title={"Add AliExpress Product ID"}
      isScrollable={false}
      className="d-flex justify-content-center align-items-center w-100 search-product-by-ids-popup-container"
    >
      <PrimaryInput name={"product-id"} value={searchProductId || ''} handleChange={handleProductSearchById} placeholder={"Enter AliExpress Product Id"} />
      <div className="d-flex align-items-center justify-content-end mt-4">
        <Button className="me-3" onClick={handlePopup}>Cancel</Button>
        <LoadingButton text={"Confirm"} handleClick={searchProductById} color="primary" isLoading={loading} />
      </div>
    </PrimaryPopup>
  );
};

export default AliExpressProductSearchPopup;
