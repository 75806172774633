import React, {useEffect} from "react";
import { Button } from "reactstrap";
import { getAdminAECredentials } from "services/AliExpressServices";


export default function AELoginBtn({ className }) {
    const searchParams = new URLSearchParams(window?.location?.search);
    useEffect(()=>{
        const code = searchParams.get("code");
        if(code){
            handleAELogin("",code);
        }
    },[searchParams.get("code")]);

  const handleAELogin = async (e,code) => {
    try {
      const response = await getAdminAECredentials(code);
      if(response){
        window.open(response);
      }
      console.log(response, "check response");
    } catch (error) {
      console.log(error, "check error");
    }
  };
  return (
    <Button color="primary" className={className} onClick={handleAELogin}>
      Login With Ali express
    </Button>
  );
}
