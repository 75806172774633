import { getApi } from "./BaseService";
import axiosInstance from "./axiosInstance";
import { getHttpHeaders } from "./BaseService";
import { siteApiUrl } from "./BaseService";

export const getAllOrders = (page,search,status) => {
    return getApi({
        url: 'admin/orders',
        data: {
            is_admin: true,
            str:search || undefined,
            page:page || undefined,
            ...status
        }
    }
    ).then(res => res.data)
};

export const getOrderDetail = (id) => {
    return getApi({
        url: `admin/orders/detail/${id}`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => res.data)
};

export const messageCreate = (data) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/orders/create-message`,
        method: "POST",
        data: {
            is_admin:true,
            ...data
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
};

export const getAllAdditionalMesssaegs = (id,page) => {
    return getApi({
        url: `admin/orders/messages/${id}`,
        data: {
            is_admin: true,
            page:page || undefined,
        }
    }
    ).then(res => res.data)
};

export const messageDelete = (id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/orders/message/${id}`,
        method: "DELETE",
        data: {
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
};

export const getTrackingLineItemsDetail = (id) => {
    return getApi({
        url: `admin/orders/tracking/${id}`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => res.data)
}

export const deleteTracking = (id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/orders/tracking/${id}`,
        method: "DELETE",
        data: {
            is_admin:true,
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
};

export const updateTracking = (data) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/orders/tracking/update`,
        method: "POST",
        data: {
            is_admin:true,
            ...data
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
}

export const getShippingAddress = (id) => {
    return getApi({
        url: `admin/orders/shipping/${id}`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => res.data)
};

export const updateShippingAddress = (data,id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/orders/shipping-updt/${id}`,
        method: "POST",
        data: {
            is_admin:true,
            ...data
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
};

export const getBillingAddress = (id) => {
    return getApi({
        url: `admin/orders/billing/${id}`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => res.data)
};


export const updateBillingAddress = (data,id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/orders/billing-updt/${id}`,
        method: "POST",
        data: {
            is_admin:true,
            ...data
        },
		withCredentials:true,
        headers: getHttpHeaders(),
    });
};

export const getTrackProductDetail = (id) => {
    return getApi({
        url: `admin/orders/get-tracking/${id}`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => res.data)
};

export const createTracking = (data,id) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/orders/add-tracking/${id}`,
        method: "POST",
        data: {
            is_admin:true,
            ...data
        },
		withCredentials:true,
        
        headers: getHttpHeaders(),
    });
};

export const getTrackingLineItDetail = (id) => {
    return getApi({
        url: `admin/orders/get-tracking/${id}`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => res.data)
};

export const holdOrder = (id,status) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/orders/hold/${id}/${status}`,
        method: "POST",
        data: {
            is_admin:true,
        },
		withCredentials:true,
        
        headers: getHttpHeaders(),
    });
};

export const approveOrderPayment = (id,sellerId) => {
    return getApi({
        url: `admin/orders/payment-approve/${id}/${sellerId}`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => res.data)
};


export const updateEmail = (id,email) => {
    return axiosInstance({
        url: `${siteApiUrl}admin/orders/email-updt/${id}`,
        method: "POST",
        data: {
            is_admin:true,
            email
        },
		withCredentials:true,
        
        headers: getHttpHeaders(),
    });
};

export const getDownloadOrdersCsv = (filterObj) => {
    return axiosInstance(
        {
            url: `${siteApiUrl}admin/orders/export-csv`,
            method:'get',
            params:{
                is_admin:true,
                ...filterObj,
            },
            withCredentials:true,
            responseType:'blob',
            headers: getHttpHeaders(),
        }
    );
};

export const importCSV = (file) => {
    const formData = new FormData();
    if(file){
        formData?.append("import",file)
    };
    formData.append("is_admin",true);
    return axiosInstance(
        {
            url: `${siteApiUrl}admin/orders/import-csv`,
            method:'POST',
            data:formData,
            withCredentials:true,
            responseType:'text',
            headers: getHttpHeaders(),
        }

    );
};


export const getRefundData = (id) => {
    return getApi({
        url: `admin/orders/refund-data/${id}`,
        data: {
            is_admin: true,
        }
    }
    ).then(res => res.data)
};



export const createRefund = (data) => {
    return axiosInstance(
        {
            url: `${siteApiUrl}admin/orders/order-refund`,
            method:'POST',
            data:{
                is_admin:true,
                ...data,
            },
            withCredentials:true,
            headers: getHttpHeaders(),
        }

    );
};




// export const getTrackingHistories = (id,page) => {
//     return getApi({
//         url: `admin/orders/track-history/${id}`,
//         data: {
//             is_admin: true,
//             page:page || undefined
//         }
//     }
//     ).then(res => res.data)
// };

export const orderDetailHistories = (orderNo,page) => {
    return getApi({
        url: `admin/orders/history/${orderNo}`,
        data: {
            is_admin: true,
            page:page || undefined
        }
    }
    ).then(res => res.data)
}


export const getAllProductsGroups = (search) => {
    return getApi({
        url: `admin/orders/product-groups`,
        data: {
            is_admin: true,
            search:search || undefined,
        }
    }
    ).then(res => res.data)
};


export const getDigitalFiles = (data) => {
    return getApi({
        url: `admin/orders/digital-product`,
        data: {
            is_admin: true,
            ...data
        }
    }
    ).then(res => res.data)
}


export const getAllSellersList = (search) => {
    return getApi({
        url: `admin/orders/sellers`,
        data: {
            is_admin: true,
            search:search || undefined,
        }
    }
    ).then(res => res.data)
}
