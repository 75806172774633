import React from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { openLinkInNewTab } from "utils/linkHandling";

const PrimaryTable = ({
  keyField,
  columns,
  data,
  selectRow,
  bordered,
  striped,
  wrapperClasses,
  path,
  rowUrl,
  isPermission,
  startDate,
  endDate,
  handleRowClick,
}) => {
  const emptyDataMessage = () => {
    return (
      <div className="text-center font-semi-bold py-4 fs-5">
        There is no data to display
      </div>
    );
  };

  const rowClasses = row => {
    if (path == "complaint") {
      if (row.status == 2 || row.status == 3) {
        return "cursor-pointer";
      }
    } else if (path == "seller-stores") {
      if (
        (row.verify_seller === 0 || row.verify_seller === 1) &&
        row.is_active === 2
      ) {
        return "banned-row";
      }
    } else {
      if (rowUrl || path == "seo-collections") {
        return "cursor-pointer";
      }
    }

    return "";
  };

  const rowEvent = {
    onClick: (e, row, rowIndex) => {
      if (isPermission) {
        if (path === "complaint") {
          if (row.status == 2 || row.status == 3) {
            openLinkInNewTab(`/others/complaints/details/${row.id}`);
          } else {
            return;
          }
        } else if (path === "product-sales-report") {
          openLinkInNewTab(
            `/reports/sales-by-varient/${row.product_id}?start-date=${startDate}&end-date=${endDate}`
          );
        } else if (path == "seo-collections") {
          handleRowClick(row.id);
        } else if (path === "sales-by-group") {
          openLinkInNewTab(`${rowUrl}&group=${row.seller_group}`);
        } else {
          openLinkInNewTab(`${rowUrl}/${row.id}`);
        }
      }
    },
  };

  return (
    <BootstrapTable
      keyField={keyField}
      responsive
      bordered={bordered}
      striped={striped}
      columns={columns}
      data={data || []}
      classes={"table align-middle table-nowrap"}
      headerWrapperClasses={"thead-light"}
      noDataIndication={emptyDataMessage}
      rowEvents={rowEvent}
      selectRow={selectRow}
      rowStyle={{ verticalAlign: "top" }}
      rowClasses={rowClasses}
      wrapperClasses={wrapperClasses}
    />
  );
};
export default PrimaryTable;
